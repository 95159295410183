import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PrivatePage } from "components/layouts/PrivatePage";
import { Patient, Beneficiary } from "components/views/PrescriptionDetails/Patient";
import { RecipeCheck } from "components/views/PrescriptionDetails/RecipeCheck";
import { AddedMedications } from "components/views/PrescriptionDetails/AddedMedications";
import { RejectRecipe } from "components/views/PrescriptionDetails/RejectRecipe";
import { FooterActions } from "components/views/PrescriptionDetails/FooterActions";

import {
    getOnePrescription,
    setAnalysesStatus,
    AnalyzeStatus,
    getReprovalReasons,
    getPrescriptionImage,
} from "services/api/prescription/prescriptionService";

import { useAppDispatch, useAppSelector } from "hooks/store";

import {
    updateApprovedProducts,
    resetApprovedProducts,
    resetDetails,
    resetReprovalReasons,
    setDetails,
    setLoadingDetailPageOn,
    setLoadingDetailPageOff,
} from "store/modules/prescriptionDetail";

import {
    verifyAnalysisStatus,
    handleAnalysisStatus,
} from "services/api/recipeStatus/recipeStatusService";

import { getUserBySocialId } from "services/api/user/userService";
import { toast, Toaster } from "react-hot-toast";
import { Toast, ToastType } from "components/widgets/atoms/Toast";

import moment from "moment";

import {
    setImageLoadingOff,
    setImageLoadingOn,
    setLoadingOff,
    setLoadingOn,
} from "store/modules/loader";

import { Loader } from "components/widgets/molecules/Loader";

import { useDebouncedEffect } from "hooks/useDebouncedEffect";
import { RejectIA } from "components/views/PrescriptionDetails/RejectIA";

export function PrescriptionDetail() {
    const dispatch = useAppDispatch();
    const { objectId } = useParams();
    const navigate = useNavigate();
    const [councilWarn, setCouncilWarn] = useState<Boolean>(false);
    const { loadingDetailPage } = useAppSelector((state) => state.prescriptionDetail); 
    const [reasonsRejectIa, setReasonsRejectIa] = useState<Array<String | null>>([]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    const { username } = useAppSelector((state) => state.login);
    const { data: { files, socialId, customerId } } = useAppSelector((state) => state.prescriptionDetail);

    let imageTryCounter = 1;

    useEffect(() => {
        setAnalysesStatus({
            ObjectId: objectId || "",
            Username: username,
            Status: AnalyzeStatus.InAnalysis,
        });
    }, [objectId, username]);

    useEffect(() => {
        return () => {
            setAnalysesStatus({
                ObjectId: objectId || "",
                Username: username,
                Status: AnalyzeStatus.Analysed,
            });
        };
    }, [objectId, username]);

    const { data, isLoading: isLoadingPrescription, error } = useQuery({
        queryKey: [`prescription${objectId}`],
        queryFn: () => getOnePrescription(objectId || ""),
        refetchOnWindowFocus: false,
    });

    const [isRecipePending, setRecipePending] = useState<boolean>(false);

    useEffect(() => {
        if (data) {
            const isPending = data.data.data.status.status === "AnalisyPending";
            setRecipePending(isPending);
        }
    }, [data]);

    const { data: reprovalReasonsData, isLoading: isLoadingReprovalReasons } = useQuery({
        queryKey: ["reprovalreasons"],
        queryFn: () => getReprovalReasons(),
        refetchOnWindowFocus: false,
    });

    const [imagesPath, setImagesPath] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState<Array<Beneficiary>>([]);
    const [startAnalyseDateTime, setStartAnalyseDateTime] = useState<moment.Moment>();

    useEffect(() => {
        setStartAnalyseDateTime(moment().utc());
        dispatch(setImageLoadingOn());

        return () => {
            dispatch(resetApprovedProducts());
            dispatch(resetReprovalReasons());
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetDetails());
        };

    }, [dispatch]);


    const getImages = useCallback(async () => {
        dispatch(setImageLoadingOn());
        try {
            const fileNames = files?.map((file) => file.fileName);

            if (fileNames.length !== 0) {
                const { data } = await getPrescriptionImage(fileNames);

                const firebaseUrls = data.data?.map((image) => image.firebaseUrl);
                setImagesPath(firebaseUrls);
            } else {
                throw new Error("Sem imagem.");
            }
        } catch (err) {
            if (imageTryCounter === 3) return;

            imageTryCounter++;
            setTimeout(async () => {
                getImages();
            }, 7000);
        } finally {
            dispatch(setImageLoadingOff());
        }
    }, [dispatch, files, imageTryCounter]);

    const kickUser = useCallback(() => {
        setTimeout(() => {
            navigate("/");
        }, 3000);

        return toast.custom(
            (t) => (
                <Toast
                    t={t}
                    text="A receita está em análise por outro usuário."
                    type={ToastType.Error}
                />
            ),
            { id: "invalid" }
        );
    }, [navigate]);

    const fetchActiveAnalysis = useCallback(async () => {
        try {
            const body = {
                username,
                objectId,
                status: 1,
            };
            await handleAnalysisStatus(body);
        } catch (err) {}
    }, [objectId, username]);

    const fetchAnalysis = useCallback(async () => {
        try {
            const response = await verifyAnalysisStatus({
                username,
                objectId,
            });

            if (response.status && response.data && response.data.data.username === username) {
                return;
            }
            if (
                response.status &&
                response.data &&
                response.data.data.username !== username &&
                isRecipePending
            ) {
                kickUser();
            }

            if (!response.status) fetchActiveAnalysis();
        } catch (err) {
            if (err.response?.data?.message === "Prescription already in analysis") {
                kickUser();
            }
        }
    }, [fetchActiveAnalysis, isRecipePending, kickUser, objectId, username]);

    useEffect(() => {
        const sendIsActive = setInterval(() => {
            fetchActiveAnalysis();
        }, 10 * 1000);

        return () => {
            clearInterval(sendIsActive);
        };
    }, [fetchActiveAnalysis]);

    const getList = useCallback(
        async (socialId: string, customerId: string) => {
            try {
                if (!socialId || socialId !== data?.data.data.socialId) {
                    return;
                } 
                    
                const response = await getUserBySocialId(socialId);
                const userData = response.data.data.filter(t => t.customer.id === customerId && 
                    (t.socialId === socialId || t.dependents.find(d => d.socialId === socialId)))[0];
                const { name, cardNumber, dependents } = userData;
                const dependentsModel = dependents?.map((dependent) => ({
                    name: dependent.name,
                    cardNumber: dependent.cardNumber,
                }));

                setBeneficiaries([{ name, cardNumber }, ...dependentsModel]);
                fetchAnalysis();
            } catch (err) {
                console.error(err);
            }
        },
        [fetchAnalysis, socialId]
    );

    useDebouncedEffect(
        () => {
            getImages();
        },
        [getImages],
        2000
    );

    useEffect(() => {
        getList(socialId, customerId);
    }, [getList, socialId, customerId]);

    useEffect(() => {
        const isLoading = isLoadingPrescription || isLoadingReprovalReasons;

        if (isLoading) {
            dispatch(setLoadingOn());
        } else {
            dispatch(setLoadingOff());
        }
    }, [dispatch, isLoadingPrescription, isLoadingReprovalReasons]);
    

    const formatPendingDetails = useCallback(async (formattedData) => {
        const labels = ['tipo_do_conselho_crm_ou_cro', 'numero_do_conselho', 'uf_do_conselho', 'data_da_receita']
        if(formattedData?.data?.ocrData) {
            let clonedData = JSON.parse(JSON.stringify(formattedData))
            let ocrDataParsed = typeof clonedData.data.ocrData === 'string' ? 
                JSON.parse(clonedData.data.ocrData) : 
                clonedData.data.ocrData

            let reasonsRejectIAStringArray: string[] = [];

            for (const reason of clonedData.data?.reasonsRejectIA || []) {
                if (reason) {
                    reasonsRejectIAStringArray.push(...Object.values(reason) as string[]);
                }
            }

            setReasonsRejectIa(reasonsRejectIAStringArray);

            const ocrResults = ocrDataParsed?.filter(crop => labels.includes(crop.label))

            const ocrResultsReduced = ocrResults?.reduce((acc, next) => {
                acc[next.label] = next.ocr
                return acc
            }, {})

            const ocrResultsReducedScore = ocrResults?.reduce((acc, next) => {
                acc[next.label] = next.ocr_score
                return acc
            }, {})
    
            if (ocrResultsReduced) {
                let [dia, mes, ano] = ocrResultsReduced["data_da_receita"].split("/")

                const formattedString = ocrResultsReduced["data_da_receita"] ? `${ano}-${mes}-${dia}T00:00:00.000Z` : ''
                
                const updatedObj = {
                    dosage: 1,
                    councilType: ocrResultsReduced["tipo_do_conselho_crm_ou_cro"],
                    crm: parseInt(ocrResultsReduced["numero_do_conselho"]) || 0,
                    UF: ocrResultsReduced["uf_do_conselho"],
                    date: formattedString,
                }

                const ocrCouncilType = ocrResultsReduced["tipo_do_conselho_crm_ou_cro"] === "CRO" ? 2 : 1

                clonedData.data.regionalCouncil = ocrResultsReducedScore["numero_do_conselho"] === null ? clonedData.data.regionalCouncil : updatedObj.crm
                clonedData.data.regionalCouncilType = ocrResultsReducedScore["tipo_do_conselho_crm_ou_cro"] === null ? clonedData.data.regionalCouncilType : ocrCouncilType
                clonedData.data.stateRegionalCouncil = ocrResultsReducedScore["uf_do_conselho"] === null ? clonedData.data.stateRegionalCouncil : updatedObj.UF
                clonedData.data.prescriptionDate = ocrResultsReducedScore["data_da_receita"] === null ? clonedData.data.prescriptionDate : (updatedObj.date ? new Date(updatedObj.date).toISOString() : clonedData.data.prescriptionDate)  
                clonedData.data.ocrData = ocrDataParsed     
            }
    
            return clonedData
        }
    
        return formattedData
    }, [])

    const formatDetails = useCallback(async (dataPayload) => {
        let formattedData = {...dataPayload.data}

        if (formattedData.data.status.status === 'AnalisyPending') {
            formattedData = await formatPendingDetails(formattedData)
        }
        
        if(typeof formattedData.data.ocrData === 'string') {
            formattedData.data.ocrData = JSON.parse(formattedData.data.ocrData)
        }

        return formattedData
    }, [formatPendingDetails])

    const loadDataAndUpdatedDetails = useCallback(async () => {
        try {
            dispatch(setLoadingDetailPageOn())

            if (data && typeof data !== "function") {
                const isCurrentObjectId = data.data.data.objectId === objectId;

                if (isCurrentObjectId) {
                    const formattedDetails = await formatDetails(data)
                    dispatch(setDetails(formattedDetails));
                    if(formattedDetails.data.approvedProducts) {
                        dispatch(updateApprovedProducts(formattedDetails.data.approvedProducts))
                    }
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            dispatch(setLoadingDetailPageOff())
        }
    }, [data, dispatch, formatDetails, objectId])

    useEffect(() => {
        loadDataAndUpdatedDetails()
    }, [loadDataAndUpdatedDetails]);

    function validateWarnStatus(value) {
        setCouncilWarn(value);
    }

    if (isLoadingPrescription || isLoadingReprovalReasons) {
        return <div>loading...</div>;
    }

    if (error) {
        return <div>erro</div>;
    }

    return (
        <PrivatePage>
            <section className="container mx-auto mb-20 mt-12 space-y-10">
            {(isRecipePending && reasonsRejectIa && reasonsRejectIa.length > 0) && (
                <RejectIA reasonsRejectIa={reasonsRejectIa} />
            )}

                <Patient beneficiaries={beneficiaries} />

                <RecipeCheck
                    imagesPath={imagesPath}
                    validateWarnStatus={validateWarnStatus}
                />

                <AddedMedications />
                <RejectRecipe reasonsData={reprovalReasonsData?.data.data} />
                <FooterActions
                    startAnalyseDateTime={startAnalyseDateTime}
                    councilWarn={councilWarn}
                />
            </section>
            {loadingDetailPage ? <Loader /> : <></>}
            <Toaster
                data-testid="snackbar"
                position="bottom-center"
                containerStyle={{
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
                toastOptions={{
                    duration: 3000,
                }}
            />
        </PrivatePage>
    );
}
