import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { Card } from "components/widgets/organisms/Card";
import { isPrescriptionPending, updatePatient, setLoadingPatientOn, setLoadingPatientOff, setLoadedPatientOn } from "store/modules/prescriptionDetail";
import { countMatchingParts } from './utils/countMatchingParts';

export type Beneficiary = {
    name: string;
    cardNumber: string;
};

type Props = {
    beneficiaries: Beneficiary[];
};

export function Patient({ beneficiaries }: Props) {
    const { data, loadedPatient, loadingPatient } = useAppSelector((state) => state.prescriptionDetail);
    const isPending = useAppSelector(isPrescriptionPending);

    const { protocol, cardNumber, ocrData, status, iAConfig } = data;

    const [selectedCardNumber, setCardNumber] = useState<string>(cardNumber);

    const [showOcrBeneficiary, setShowOcrBeneficiary] = useState<boolean>(false);

    const selectBeneficiary = useRef<HTMLSelectElement>(null);
    const dispatch = useAppDispatch();

    const ocrResult = ocrData?.filter(crop => crop.label === "nome_do_paciente")
    
    const ocrResultsReduced = ocrResult?.reduce((acc, next) => {
        acc[next.label] = next.ocr
        return acc
    }, {})

    useEffect(() => {
        if(loadingPatient || loadedPatient) return
        if(!status?.status || beneficiaries.length === 0) return
        dispatch(setLoadingPatientOn())
        setShowOcrBeneficiary(false)

        if (isPending) {
            if(beneficiaries.length > 0) {
                let bestMatch: Beneficiary | null = null;
                let highestMatchCount = 0;

                if(ocrResultsReduced) {
                    for(let beneficiary of beneficiaries) {
                        const matchCount = countMatchingParts(beneficiary?.name, ocrResultsReduced["nome_do_paciente"]);
    
                        if (matchCount > highestMatchCount) {
                            highestMatchCount = matchCount;
                            bestMatch = beneficiary;
                        }
                    }
                }
                
                if (bestMatch){
                    setShowOcrBeneficiary(true)

                    dispatch(updatePatient(bestMatch))
                    setCardNumber(bestMatch?.cardNumber);

                    const index = beneficiaries.findIndex(
                        (beneficiary) => beneficiary.cardNumber === bestMatch?.cardNumber
                    );
                
                    if (index >= 0 && selectBeneficiary.current) {
                        selectBeneficiary.current.selectedIndex = index;
                    }
                } else {
                    setCardNumber(cardNumber);

                    const index = beneficiaries?.findIndex(
                        (beneficiary) => beneficiary.cardNumber === cardNumber
                    );

                    if (index >= 0 && selectBeneficiary.current) {
                        selectBeneficiary.current.selectedIndex = index;
                    }
                }
            }
        } 
        dispatch(setLoadedPatientOn())
        dispatch(setLoadingPatientOff())
    }, [isPending, beneficiaries, ocrResultsReduced, dispatch, loadedPatient, loadingPatient, status?.status]);


    useEffect(() => {
        if(!isPending || manuallyChangingBeneficiary) {
            setCardNumber(cardNumber);

            const index = beneficiaries?.findIndex(
                (beneficiary) => beneficiary.cardNumber === cardNumber
            );

            if (index >= 0 && selectBeneficiary.current) {
                selectBeneficiary.current.selectedIndex = index;
            }
        }
    }, [beneficiaries, cardNumber]);

    const [ manuallyChangingBeneficiary, setManuallyChangingBeneficiary] = useState(false)

    function handleChangeBeneficiary(event: ChangeEvent<HTMLSelectElement>) {
        const selectedIndex = event.target.value;
        const selectedBeneficiary = beneficiaries[selectedIndex];

        dispatch(updatePatient(selectedBeneficiary));
        setCardNumber(selectedBeneficiary.cardNumber);
        setManuallyChangingBeneficiary(true)
    }

    return (
        <Card>
            <h3 className="text-[2rem] font-bold text-vidalink-blue">Dados do paciente</h3>

            <div
                data-testid="patient-card"
                className="flex flex-col justify-between gap-4 xl:flex-row"
            >
                <div className="form-control flex-1">
                    <label htmlFor="protocol" className={showOcrBeneficiary ? "mb-7" : ""}>Protocolo</label>
                    <input type="text" disabled value={protocol} />
                </div>

                {/* Campo OCR: beneficiario */}
                <div className="form-control flex-1" >
                    <label htmlFor="protocol">Beneficiário</label>
                    { 
                        showOcrBeneficiary &&
                        ocrResult?.map((filteredResult, index) => (
                            <small
                                className={filteredResult?.ocr_score >= iAConfig?.thresholdBeneficiario ? "text-vidalink-green font-medium" : "text-vidalink-magenta font-medium"}
                                key={index}
                            >
                                { Math.floor(filteredResult.ocr_score * 100) }% confiável - {filteredResult?.ocr_score >= iAConfig?.thresholdBeneficiario ? "APROVADO" : "REPROVADO"}
                            </small>
                        )) 
                        
                    }
                    <select
                        disabled={!isPending}
                        onChange={handleChangeBeneficiary}
                        ref={selectBeneficiary}
                    >
                        {beneficiaries?.map((beneficiary, index) => (
                            <option key={beneficiary.cardNumber} value={index}>
                                {beneficiary.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-control flex-1">
                    <label htmlFor="protocol" className={showOcrBeneficiary ? "mb-7" : ""}>Número do cartão</label>
                    <input id="protocol" type="text" disabled value={selectedCardNumber} />
                </div>
            </div>
        </Card>
    );
}
